import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import LibraryForm from '../components/LibraryForm';
import LibraryTabs from '../components/LibraryTabs';
import { UPDATE_LIBRARY_ADMIN } from '../graphql/Mutations';
import { LIBRARY_ADMIN } from '../graphql/Queries';

const BasicDetailsEdit = ({
  match: { params: { id, libraryNumber } = {} } = {},
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [libraryDetail, setLibraryDetail] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isLibraryLoading, setIsLibraryLoading] = useState(true);

  const [updateLibraryAdmin] = useMutation(UPDATE_LIBRARY_ADMIN, {
    onError: () => {},
  });

  const [libraryAdmin] = useLazyQuery(LIBRARY_ADMIN, {
    onCompleted: (response) => {
      setLibraryDetail(response?.libraryAdmin);
      setIsLibraryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsLibraryLoading(true);
    setLibraryDetail([]);
    libraryAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const updateLibraryFun = async (values) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await updateLibraryAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          city: values?.city,
          state: values?.state,
          contactNo: values?.contact,
          address: values?.address?.trim(),
          email: values?.email?.trim(),
          latLong: {
            latitude: parseFloat(values?.latitude),
            longitude: parseFloat(values?.longitude),
          },
          firstName: values?.firstName?.trim(),
          lastName: values?.lastName?.trim(),
          name: values?.name?.trim(),
          pincodes: values?.libraryPincode,
          preferredTimeFrom: values?.from?.$d,
          preferredTimeTo: values?.to?.$d,
          serviceableArea: parseFloat(values?.serviceableArea),
          types: values?.facilityType,
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const initialValues = {
    ...libraryDetail,
    id: libraryDetail?.libraryNumber,
    contact: libraryDetail?.librarian?.contactNo,
    email: libraryDetail?.librarian?.email,
    firstName: libraryDetail?.librarian?.firstName,
    lastName: libraryDetail?.librarian?.lastName,
    pincode: libraryDetail?.librarian?.pincode?.id,
    state: libraryDetail?.state,
    city: libraryDetail?.city,
    from: dayjs(libraryDetail?.preferredTimeFrom),
    to: dayjs(libraryDetail?.preferredTimeTo),
    libraryPincode: libraryDetail?.pincodes?.map((pincode) => pincode?.pincode),
    backupLibName: libraryDetail?.backUpLibrary?.name,
    backupLibId: libraryDetail?.backUpLibrary?.libraryNumber,
    backupLibLibrarianFirstName:
      libraryDetail?.backUpLibrary?.librarian?.firstName,
    backupLibLibrarianLastName:
      libraryDetail?.backUpLibrary?.librarian?.lastName,
    backupLibFrom: dayjs(libraryDetail?.backUpLibrary?.preferredTimeFrom),
    backupLibTo: dayjs(libraryDetail?.backUpLibrary?.preferredTimeTo),
    backupLibLibrarianContact:
      libraryDetail?.backUpLibrary?.librarian?.contactNo,
    backupLibLibrarianEmail: libraryDetail?.backUpLibrary?.librarian?.email,
    latitude: libraryDetail?.latLong?.coordinates?.[1],
    longitude: libraryDetail?.latLong?.coordinates?.[0],
    referralCode: libraryDetail?.referralCode,
    availableBalance: libraryDetail?.availableBalance,
    serviceableArea: libraryDetail?.serviceableArea,
    facilityType: libraryDetail?.types,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`}
        />
        <div className="portal-header">Edit Library</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() => {
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`);
            }}
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() => {
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`);
            }}
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <LibraryTabs
        libraryId={id}
        libraryNumber={libraryNumber}
        onboardedOn={libraryDetail?.createdAt}
      >
        {isLibraryLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isLibraryLoading}
          />
        ) : (
          <div className="library-form">
            <LibraryForm
              form={form}
              onFinish={updateLibraryFun}
              handleShowPrompt={handleShowPrompt}
              initialValues={initialValues}
              libraryDetail={libraryDetail}
            />
          </div>
        )}
      </LibraryTabs>
    </>
  );
};

export default BasicDetailsEdit;
