import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES, TABS } from '../../../../../common/constants';

const TransferTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(TABS?.IN?.key);

  const tabList = [
    {
      key: TABS?.IN?.key,
      label: TABS?.IN?.label,
      children,
    },
    {
      key: TABS?.OUT?.key,
      label: TABS?.OUT?.label,
      children,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.IN)) {
      setActiveTabKey(TABS?.IN?.key);
    }
    if (location?.pathname?.includes(ROUTES?.OUT)) {
      setActiveTabKey(TABS?.OUT?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.IN?.key:
        history?.push(`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.IN}`);
        break;
      case TABS?.OUT?.key:
        history?.push(`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.OUT}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-container mobile-card-main">
        <Tabs
          activeKey={activeTabKey}
          type="card"
          className="full-width"
          onChange={(key) => onTabChange(key)}
          items={tabList}
          destroyInactiveTabPane
        />
      </div>
    </>
  );
};

export default TransferTabs;
