import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES, USER_LIBRARY_TYPE } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import { UPDATE_USER } from '../../../graphql/Mutations';
import { USER } from '../../../graphql/Queries';
import EndUserForm from '../components/EndUserForm';
import EndUsersTabs from '../components/EndUsersTabs';

const EditEndUser = ({ match: { params: { id, userNumber } = {} } = {} }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [endUserDetail, setEndUserDetail] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isEndUserLoading, setIsEndUserLoading] = useState(true);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: () => {},
  });

  const [users] = useLazyQuery(USER, {
    onCompleted: (response) => {
      setEndUserDetail(response?.user);
      setIsEndUserLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsEndUserLoading(true);
    setEndUserDetail([]);
    users({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const updateAdminFun = async (values) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await updateUser({
      variables: {
        where: {
          id,
        },
        data: {
          firstName: values?.firstName?.trim(),
          lastName: values?.lastName?.trim(),
          gender: values?.gender,
          city: values?.city?.trim(),
          state: values?.state?.trim(),
          contactNo: values?.contact?.trim(),
          address: values?.address?.trim(),
          email: values?.email?.trim(),
          pincode: values?.pincode,
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const primaryLibrary = endUserDetail?.userLibraries?.find(
    (lib) => lib?.type === USER_LIBRARY_TYPE?.PRIMARY?.value,
  );

  const initialValues = {
    ...endUserDetail,
    contact: endUserDetail?.contactNo,
    status: endUserDetail?.isActive,
    state: endUserDetail?.state,
    city: endUserDetail?.city,
    pincode: endUserDetail?.pincode?.pincode,
    id: endUserDetail?.userNumber,
    mappedLibrary:
      primaryLibrary?.library?.name || endUserDetail?.pincode?.library?.name,
    lat: endUserDetail?.latLong?.coordinates?.[1],
    lng: endUserDetail?.latLong?.coordinates?.[0],
    additionalAddress:
      endUserDetail?.additionalAddressDetails?.formattedAddress,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
        />
        <div className="portal-header">Edit End User</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`)
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`)
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <EndUsersTabs
        endUserId={id}
        endUserNumber={userNumber}
        onboardedOn={endUserDetail?.createdAt}
      >
        {isEndUserLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isEndUserLoading}
          />
        ) : (
          <div className="endUser-form">
            <EndUserForm
              form={form}
              onFinish={updateAdminFun}
              handleShowPrompt={handleShowPrompt}
              initialValues={initialValues}
              endUserDetail={endUserDetail}
            />
          </div>
        )}
      </EndUsersTabs>
    </>
  );
};

export default EditEndUser;
