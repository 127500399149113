import { Divider } from 'antd';

import React from 'react';
import {
  DATE_FORMATS,
  TRANSFER_ORDER_STATUS,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import ModalComponent from '../../../../../components/ModalComponent';

const ItemStatusModal = ({
  title,
  isModalOpen,
  setIsModalOpen,
  statusHistories,
}) => {
  const columns = [
    {
      title: 'PREVIOUS STATUS',
      dataIndex: 'oldStatus',
      key: 'oldStatus',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        TRANSFER_ORDER_STATUS?.[record?.oldStatus]?.label || '-',
    },
    {
      title: 'UPDATED STATUS',
      dataIndex: 'newStatus',
      key: 'newStatus',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        TRANSFER_ORDER_STATUS?.[record?.newStatus]?.label || '-',
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME) || '-',
    },
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      title={title}
      width={600}
    >
      <Divider />
      <div className="mt-24">
        <CommonTable
          columns={columns}
          data={statusHistories}
          scroll={{ y: 350 }}
          rowKey={(record) => record?.id}
        />
      </div>
    </ModalComponent>
  );
};

export default ItemStatusModal;
