import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { PERMISSIONS_KEY, ROUTES, TABS } from '../../../../../common/constants';
import { filterTabsByPermissions } from '../../../../../common/utils';

const TransferTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [activeTabKey, setActiveTabKey] = useState(TABS?.IN?.key);

  const tabList = [
    {
      key: TABS?.IN?.key,
      label: TABS?.IN?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.TRANSFER_ORDERS_IN,
    },
    {
      key: TABS?.OUT?.key,
      label: TABS?.OUT?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.TRANSFER_ORDERS_OUT,
    },
    {
      key: TABS?.ALL?.key,
      label: TABS?.ALL?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.TRANSFER_ORDERS_ALL,
    },
  ];

  const filteredTabs = filterTabsByPermissions(tabList, permissions);

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.IN)) {
      setActiveTabKey(TABS?.IN?.key);
    }
    if (location?.pathname?.includes(ROUTES?.OUT)) {
      setActiveTabKey(TABS?.OUT?.key);
    }
    if (location?.pathname?.includes(ROUTES?.ALL)) {
      setActiveTabKey(TABS?.ALL?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.IN?.key:
        history?.push(`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.IN}`);
        break;
      case TABS?.OUT?.key:
        history?.push(`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.OUT}`);
        break;
      case TABS?.ALL?.key:
        history?.push(`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.ALL}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-container">
        <Tabs
          activeKey={activeTabKey}
          type="card"
          className="full-width"
          onChange={(key) => onTabChange(key)}
          items={filteredTabs}
          destroyInactiveTabPane
        />
      </div>
    </>
  );
};

export default TransferTabs;
