import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation signOut {
    signOut {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      token
      user {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query currentUserProfile {
    currentUserProfile {
      address
      city
      contactNo
      createdAt
      email
      firstName
      gender
      id
      isActive
      isArchived
      lastName
      permissions
      pincode {
        id
        pincode
      }
      profileImage
      roles
      state
      type
      updatedAt
      refreshToken
      libraryId
    }
  }
`;
