import { Divider, Upload } from 'antd';
import React, { useState } from 'react';
import { getBase64 } from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import ModalComponent from '../../../../../components/ModalComponent';

const NoteModal = ({ title, order, isModalOpen, setIsModalOpen }) => {
  const [visible, setVisible] = useState(false);
  const [toyPreviewImage, setToyPreviewImage] = useState();
  const metaData = order?.metaData || {};

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setToyPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <ModalComponent
        title={title}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
      >
        <Divider />
        <div className="font-size-16">{metaData?.damageNote || '-'}</div>
        {metaData?.images?.length > 0 && (
          <div className="mb-8 mt-24">
            <div className="font-size-16 mb-8">Images:</div>
            <div className="font-size-16">
              <Upload
                name="avatar"
                listType="picture-card"
                maxCount={4}
                fileList={metaData?.images}
                beforeUpload={() => false}
                onPreview={handlePreview}
                className="avatar-uploader"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: false,
                }}
              />
            </div>
          </div>
        )}
        {metaData?.videoUrl && (
          <>
            <div className="font-size-16 mb-8">Video:</div>
            <div className="toy-video-container mb-10">
              <video
                width="100%"
                height="100%"
                controls
                src={metaData?.videoUrl}
                className="toy-video-upload"
              >
                <track kind="captions" />
              </video>
            </div>
          </>
        )}
      </ModalComponent>
    </>
  );
};

export default NoteModal;
