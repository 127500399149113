import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/svg/info-solid.svg';
import {
  DATE_FORMATS,
  DELIVERY_TYPE,
  FULFILLMENT_STATUS,
  LIMIT,
  ORDER_STATUS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  RETURN_ORDER_ITEM_STATUS,
  ROUTES,
  SORT,
  TRANSFER_ORDER_STATUS,
  TRANSFER_ORDER_TYPE,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { LIBRARIES } from '../../../../requests/modules/library/graphql/Queries';
import { UPDATE_TRANSFER_ORDERS_ADMIN } from '../graphql/Mutations';
import { TRANSFER_ORDER_ADMIN } from '../graphql/Queries';
import ItemStatusModal from './ItemStatusModal';
import NoteModal from './NoteModal';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialOrderFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialOrderSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const TransferOrderTable = ({
  exportFilter,
  setExportFilter,
  orderTransferType,
}) => {
  const {
    state: {
      permissions,
      commonPermissions: {
        isUserPermission,
        isToyPermission,
        isLibraryPermission,
      },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [order, setOrder] = useState({});
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isRequestPrompts, setIsRequestPrompts] = useState(false);
  const [isRequestLoadings, setIsRequestLoadings] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRequestsPrompts, setIsRequestsPrompts] = useState(false);
  const [isNotePrompts, setIsNotePrompts] = useState(false);
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false);

  const transferTypeIn = orderTransferType === TRANSFER_ORDER_TYPE?.IN?.key;

  const hasUpdatePermission = hasPermission(
    transferTypeIn
      ? PERMISSIONS_KEY?.TRANSFER_ORDERS_IN
      : PERMISSIONS_KEY?.TRANSFER_ORDERS_OUT,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  const [transferOrdersAdmin] = useLazyQuery(TRANSFER_ORDER_ADMIN, {
    onCompleted: (response) => {
      setOrderList([...response?.transferOrdersAdmin?.data]);
      if (
        response?.transferOrdersAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.transferOrdersAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [
    receivingLibraries,
    { loading: isReceivingLibrariesLoading },
  ] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [updateTransferOrdersAdmin] = useMutation(
    UPDATE_TRANSFER_ORDERS_ADMIN,
    {
      onError: () => {},
    },
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    transferOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          type: orderTransferType,
          ownerLibraryIds: finalWhereFilters?.name,
          receivingLibraryIds: finalWhereFilters?.receivingLibname,
        },
        sort: initialOrderSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    transferOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          skip,
          limit: pagination?.pageSize,
          type: orderTransferType,
          search: orderSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          ownerLibraryIds: finalWhereFilters?.name,
          receivingLibraryIds: finalWhereFilters?.receivingLibname,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    transferOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          type: orderTransferType,
          search: trimValue,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          ownerLibraryIds: finalWhereFilters?.name,
          receivingLibraryIds: finalWhereFilters?.receivingLibname,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleRequest = async (values) => {
    setIsRequestLoadings(true);
    const response = await updateTransferOrdersAdmin({
      variables: {
        where: {
          transferOrderIds: values,
        },
        data: {
          status: !transferTypeIn
            ? TRANSFER_ORDER_STATUS?.SENT?.value
            : TRANSFER_ORDER_STATUS?.RECEIVED?.value,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsRequestPrompts(false);
      setIsRequestsPrompts(false);
      setSelectedRowKeys([]);
      transferOrdersAdmin({
        variables: {
          filter: {
            ...initialOrderFilter,
            limit: paginationProp?.pageSize || LIMIT,
            type: orderTransferType,
            search: orderSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            ownerLibraryIds: finalWhereFilters?.name,
            receivingLibraryIds: finalWhereFilters?.receivingLibname,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
    setIsRequestLoadings(false);
  };

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    transferOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          type: orderTransferType,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          ownerLibraryIds: whereFilter?.name,
          receivingLibraryIds: whereFilter?.receivingLibname,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOrderLoading(true);
    transferOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          type: orderTransferType,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          ownerLibraryIds: whereFiltersCopy?.name,
          receivingLibraryIds: whereFiltersCopy?.receivingLibname,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(TRANSFER_ORDER_STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'receivingLibname':
          receivingLibraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (dataIndex === 'status') {
      return;
    }
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !isReceivingLibrariesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'receivingLibname':
              receivingLibraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'receivingLibname':
        receivingLibraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'returnedOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.PAID?.value ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.FREE?.value ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'TRANSFER ORDER ID',
      dataIndex: 'transferOrderNumber',
      key: 'transferOrderNumber',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.transferOrderNumber || '-',
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      ellipsis: true,
      align: 'left',
      width: 250,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isToyPermission &&
          record?.orderItem?.toy?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.orderItem?.toy?.id}`,
          ),
        className: `${
          isToyPermission && record?.orderItem?.toy?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const toyData = record?.orderItem?.toy;
        return (
          <>
            <div className="table-data-with-id">{toyData?.name || '-'}</div>
            {toyData?.toyNumber && (
              <div className="font-size-12">({toyData?.toyNumber})</div>
            )}
          </>
        );
      },
    },
    {
      title: 'ORDER ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.orderItem?.order?.orderNumber || '-',
    },
    {
      title: 'RETURN ORDER ID',
      dataIndex: 'returnOrderId',
      key: 'returnOrderId',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) =>
        record?.returnOrderItem?.returnOrder?.returnOrderNumber || '-',
    },
    {
      title: 'ORDER STATUS',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const orderStatus = record?.orderItem?.order?.status;
        return (
          <Badge
            color={ORDER_STATUS?.[orderStatus]?.color}
            text={ORDER_STATUS?.[orderStatus]?.text}
          />
        );
      },
    },
    {
      title: 'FULFILLMENT STATUS',
      dataIndex: 'fulfillmentStatus',
      key: 'fulfillmentStatus',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) => {
        const orderItemStatus = record?.orderItem?.status;
        return orderItemStatus === FULFILLMENT_STATUS?.FULFILLED?.value ||
          orderItemStatus === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
          orderItemStatus === FULFILLMENT_STATUS?.CANCELLED?.value ? (
          <Badge
            color={FULFILLMENT_STATUS?.[orderItemStatus]?.color}
            text={FULFILLMENT_STATUS?.[orderItemStatus]?.text}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: 'RETURN ORDER STATUS',
      dataIndex: 'returnOrderstatus',
      key: 'returnOrderstatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        RETURN_ORDER_ITEM_STATUS?.[record?.returnOrderItem?.status]?.label,
    },
    {
      title: 'OWNER LIBRARY NAME',
      dataIndex: 'ownerLibraryName',
      key: 'ownerLibraryName',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.ownerLibrary?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.ownerLibrary?.id}/${record?.ownerLibrary?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission &&
          record?.ownerLibrary?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.ownerLibrary?.name}</div>
          <div className="font-size-12">
            ({record?.ownerLibrary?.libraryNumber})
          </div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'RECEIVING LIBRARY NAME',
      dataIndex: 'receivingLibraryName',
      key: 'receivingLibraryName',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.receivingLibrary?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.receivingLibrary?.id}/${record?.receivingLibrary?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission &&
          record?.receivingLibrary?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">
            {record?.receivingLibrary?.name}
          </div>
          <div className="font-size-12">
            ({record?.receivingLibrary?.libraryNumber})
          </div>
        </>
      ),
      ...filterPopup('receivingLibname'),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME),
      ...filterPopup('createdAt'),
    },
    {
      title: 'DAMAGE REQUESTED ON',
      dataIndex: 'requestedOn',
      key: 'requestedOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        record?.metaData?.requestedOn
          ? formatDate(record?.metaData?.requestedOn, DATE_FORMATS?.WITH_TIME)
          : '-',
    },
    {
      title: 'TRANSFER STATUS',
      dataIndex: 'transferStatus',
      key: 'transferStatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => TRANSFER_ORDER_STATUS?.[record?.status]?.label,
      ...filterPopup('status', false),
    },
    {
      title: 'DAMAGE NOTE',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Tooltip title="View note" placement="top" zIndex={4}>
          <CommentIcon
            className="mr-16 pointer svg-icon"
            onClick={() => {
              setIsNotePrompts(true);
              setOrder(record);
            }}
          />
        </Tooltip>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <>
          <div className="action-button">
            {hasUpdatePermission && (
              <>
                {orderTransferType === TRANSFER_ORDER_TYPE?.OUT?.key && (
                  <Button
                    type="primary"
                    size="small"
                    className="table-action-primary-btn"
                    onClick={() => {
                      setIsRequestPrompts(true);
                      setOrder(record);
                    }}
                    disabled={
                      ![TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value]?.includes(
                        record?.status,
                      )
                    }
                  >
                    Sent
                  </Button>
                )}
                {orderTransferType === TRANSFER_ORDER_TYPE?.IN?.key && (
                  <Button
                    type="primary"
                    size="small"
                    className="table-action-primary-btn"
                    onClick={() => {
                      setIsRequestPrompts(true);
                      setOrder(record);
                    }}
                    disabled={
                      ![TRANSFER_ORDER_STATUS?.SENT?.value]?.includes(
                        record?.status,
                      )
                    }
                  >
                    Received
                  </Button>
                )}
              </>
            )}
            <Tooltip title="Status Logs" placement="top" zIndex={4}>
              <InfoIcon
                onClick={() => {
                  setOrder(record);
                  setIsOrderStatusModalOpen(true);
                }}
                className="ml-16 pointer svg-icon"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const expandedRowRender = (data) => {
    const nestedData = [data];
    const nestedColumns = [
      {
        title: 'USER NAME',
        dataIndex: 'userName',
        key: 'userName',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isUserPermission &&
            record?.user?.id &&
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.user?.id}/${record?.user?.userNumber}`,
            ),
          className: `${
            isUserPermission && record?.user?.id && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => {
          const userData = record?.user;
          return (
            <>
              {userData?.firstName || userData?.lastName ? (
                <div className="table-data-with-id">{`${
                  userData?.firstName ? userData?.firstName : ''
                } ${userData?.lastName ? userData?.lastName : ''}`}</div>
              ) : (
                '-'
              )}
              {userData?.userNumber ? (
                <div className="font-size-12">({userData?.userNumber})</div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        title: 'CREATOR NAME',
        dataIndex: 'creatorName',
        key: 'creatorName',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isUserPermission &&
            record?.creator?.id &&
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.creator?.id}/${record?.creator?.userNumber}`,
            ),
          className: `${
            isUserPermission && record?.creator?.id && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => {
          const userData = record?.creator;
          return (
            <>
              {userData?.firstName || userData?.lastName ? (
                <div className="table-data-with-id">{`${
                  userData?.firstName ? userData?.firstName : ''
                } ${userData?.lastName ? userData?.lastName : ''}`}</div>
              ) : (
                '-'
              )}
              {userData?.userNumber ? (
                <div className="font-size-12">({userData?.userNumber})</div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        title: 'ACTION USER NAME',
        dataIndex: 'actionUserName',
        key: 'actionUserName',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isUserPermission &&
            record?.actionUser?.id &&
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.actionUser?.id}/${record?.actionUser?.userNumber}`,
            ),
          className: `${
            isUserPermission && record?.actionUser?.id && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => {
          const userData = record?.actionUser;
          return (
            <>
              {userData?.firstName || userData?.lastName ? (
                <div className="table-data-with-id">{`${
                  userData?.firstName ? userData?.firstName : ''
                } ${userData?.lastName ? userData?.lastName : ''}`}</div>
              ) : (
                '-'
              )}
              {userData?.userNumber ? (
                <div className="font-size-12">({userData?.userNumber})</div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        title: 'REQUEST GENERATOR',
        dataIndex: 'requestGenerator',
        key: 'requestGenerator',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isUserPermission &&
            record?.requestGenerator?.id &&
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.requestGenerator?.id}/${record?.requestGenerator?.userNumber}`,
            ),
          className: `${
            isUserPermission &&
            record?.requestGenerator?.id &&
            'pointer table-cell'
          }`,
        }),
        render: (_, record) => {
          const userData = record?.requestGenerator;
          return (
            <>
              {userData?.firstName || userData?.lastName ? (
                <div className="table-data-with-id">{`${
                  userData?.firstName ? userData?.firstName : ''
                } ${userData?.lastName ? userData?.lastName : ''}`}</div>
              ) : (
                '-'
              )}
              {userData?.userNumber ? (
                <div className="font-size-12">({userData?.userNumber})</div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];

    return (
      <CommonTable
        columns={nestedColumns}
        dataSource={nestedData || []}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: transferTypeIn
        ? ![TRANSFER_ORDER_STATUS?.SENT?.value]?.includes(record?.status)
        : ![TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value]?.includes(
            record?.status,
          ),
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <>
      <NoteModal
        title="Damage Note"
        order={order}
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRequestPrompts}
        onOk={() => handleRequest([order?.id])}
        onCancel={() => setIsRequestPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!transferTypeIn ? 'Sent request' : 'Received request'}{' '}
        </strong>
        for this?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRequestsPrompts}
        onOk={() => handleRequest(selectedRowKeys)}
        onCancel={() => setIsRequestsPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!transferTypeIn ? 'Sent requests' : 'Received requests'}{' '}
        </strong>
        for this?
      </Modal>
      <ItemStatusModal
        title="Status Logs"
        isModalOpen={isOrderStatusModalOpen}
        setIsModalOpen={setIsOrderStatusModalOpen}
        statusHistories={order?.statusHistory}
      />
      <div className="d-flex justify-end align-center mb-16">
        {hasUpdatePermission && (
          <>
            {orderTransferType !== TRANSFER_ORDER_TYPE?.ALL?.key && (
              <Button
                type="primary"
                disabled={!hasSelected}
                className="mr-8"
                onClick={() => setIsRequestsPrompts(true)}
              >
                {!transferTypeIn ? 'Sent Requests' : 'Received Requests'}
              </Button>
            )}
          </>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={orderList || []}
        loading={isOrderLoading}
        rowSelection={
          orderTransferType !== TRANSFER_ORDER_TYPE?.ALL?.key
            ? rowSelection
            : null
        }
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
        }}
      />
    </>
  );
};

export default TransferOrderTable;
