import { FilterFilled, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Checkbox, Divider, Empty, Form, Image, Tag } from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  BREAKPOINTS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
} from '../../../common/constants';
import { hasPermission } from '../../../common/utils';
import CommonPreviewTable from '../../../components/CommonPreviewTable';
import CommonTable from '../../../components/CommonTable';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import {
  AGE_GROUPS,
  CATEGORIES,
} from '../../../modules/lists/modules/toy/graphql/Queries';
import { ASSIGN_RACK } from '../../inventory/graphql/Mutations';
import {
  BRANDS,
  FACILITATES,
  LIBRARY_INVENTORIES,
  MATERIALS,
  TAGS,
} from '../../inventory/graphql/Queries';
import AddToysModal from './AddToysModal';

let filterScrollDebounceJob = null;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialSort = {
  field: 'name',
  order: 'ASC',
};

const initialToyFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const RackMasterInventory = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const { rackId } = useParams();
  const [toyForm] = Form.useForm();

  const [inventoryList, setInventoryList] = useState([]);
  const [isInventoryLoading, setIsInventoryLoading] = useState(true);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [isAddToyModalOpen, setIsAddToyModalOpen] = useState(false);
  const [isEmptyRackMasterList, setIsEmptyRackMasterList] = useState(false);
  const [toySelectValue, setToySelectValue] = useState([]);
  const [toyFile, setToyFile] = useState([]);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [libraryInventories] = useLazyQuery(LIBRARY_INVENTORIES, {
    onCompleted: (response) => {
      setInventoryList([...response?.libraryInventories?.data]);
      if (
        response?.libraryInventories?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsInventoryLoading(true);
        setIsEmptyRackMasterList(true);
      } else {
        setIsInventoryLoading(false);
        setIsEmptyRackMasterList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.libraryInventories?.count,
      };
      setPaginationProp(pagination);
      setIsInventoryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_RACK,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    setInventoryList([]);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          search: '',
          rackId,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [rackId]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsInventoryLoading(true);
    setSortedInfo(sorter);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          skip,
          limit: pagination?.pageSize,
          rackId,
          search: '',
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const [assignRack] = useMutation(ASSIGN_RACK, {
    onError: () => {},
  });

  const [brands, { loading: isBrandsLoading }] = useLazyQuery(BRANDS, {
    onCompleted: (response) => {
      if (response?.brands?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.brands?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.brands?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [categories, { loading: isCategoriesLoading }] = useLazyQuery(
    CATEGORIES,
    {
      onCompleted: (response) => {
        if (response?.categories?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.categories?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.categories?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [facilitates, { loading: isFacilitateLoading }] = useLazyQuery(
    FACILITATES,
    {
      onCompleted: (response) => {
        if (response?.facilitates?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.facilitates?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.facilitates?.data, (item) =>
          optionsCopy?.push({
            label: item?.name?.replace(/\s/g, '\u00a0'),
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [tags, { loading: isTagLoading }] = useLazyQuery(TAGS, {
    onCompleted: (response) => {
      if (response?.tags?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.tags?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.tags?.data, (item) =>
        optionsCopy?.push({
          label: item?.name?.replace(/\s/g, '\u00a0'),
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [materials, { loading: isMaterialsLoading }] = useLazyQuery(MATERIALS, {
    onCompleted: (response) => {
      if (response?.materials?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.materials?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.materials?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [ageGroups, { loading: isAgeGroupsLoading }] = useLazyQuery(
    AGE_GROUPS,
    {
      onCompleted: (response) => {
        if (response?.ageGroups?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.ageGroups?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.ageGroups?.data, (item) => {
          if (item?.minAge !== 0 || item?.maxAge !== 0) {
            optionsCopy?.push({
              label:
                item?.minAge > 18
                  ? `${Math.floor(item?.minAge / 12)} - ${Math.floor(
                      item?.maxAge / 12,
                    )} Years`
                  : `${item?.minAge} - ${item?.maxAge} Months`,
              key: item?.id,
            });
          }
        });
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      switch (filterIndex) {
        case 'brand':
          brands({
            variables: {
              filter: initialToyFilter,
              sort: initialSort,
            },
          });
          break;

        case 'category':
          categories({
            variables: {
              filter: initialToyFilter,
              sort: initialSort,
            },
          });
          break;

        case 'material':
          materials({
            variables: {
              filter: initialToyFilter,
              sort: initialSort,
            },
          });
          break;

        case 'ageGroup':
          ageGroups({
            variables: {
              filter: initialToyFilter,
              sort: {
                field: 'minAge',
                order: SORT?.ASC,
              },
            },
          });
          break;

        case 'facilitates':
          facilitates({
            variables: {
              filter: initialToyFilter,
              sort: initialSort,
            },
          });
          break;

        case 'tags':
          tags({
            variables: {
              filter: initialToyFilter,
              sort: initialSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const handleAddToys = async (values) => {
    setIsInventoryLoading(true);

    const response = await assignRack({
      variables: {
        where: {
          id: rackId,
        },
        data: {
          libraryInventoryIds: values?.toys,
        },
      },
    });

    if (response?.data) {
      setIsAddToyModalOpen(false);
      toyForm?.resetFields();
      setToyFile([]);
      setToySelectValue([]);
      setIsInventoryLoading(true);
      libraryInventories({
        variables: {
          filter: {
            ...initialToyFilter,
            search: '',
            rackId,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsInventoryLoading(false);
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'brand':
        brands({
          variables: {
            filter: {
              ...initialToyFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialSort,
          },
        });
        break;

      case 'category':
        categories({
          variables: {
            filter: {
              ...initialToyFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialSort,
          },
        });
        break;

      case 'material':
        materials({
          variables: {
            filter: {
              ...initialToyFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialSort,
          },
        });
        break;

      case 'ageGroup':
        ageGroups({
          variables: {
            filter: {
              ...initialToyFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'minAge',
              order: SORT?.ASC,
            },
          },
        });
        break;

      case 'facilitates':
        facilitates({
          variables: {
            filter: {
              ...initialToyFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialSort,
          },
        });
        break;

      case 'tags':
        tags({
          variables: {
            filter: {
              ...initialToyFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: '',
          brandIds: whereFiltersCopy?.brand,
          categoryIds: whereFiltersCopy?.category,
          materialIds: whereFiltersCopy?.material,
          ageGroupIds: whereFiltersCopy?.ageGroup,
          facilitateIds: whereFiltersCopy?.facilitates,
          tagIds: whereFiltersCopy?.tags,
          rackId,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isBrandsLoading &&
      !isCategoriesLoading &&
      !isMaterialsLoading &&
      !isAgeGroupsLoading &&
      !isFacilitateLoading &&
      !isTagLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'brand':
              brands({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialSort,
                },
              });
              break;

            case 'category':
              categories({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialSort,
                },
              });
              break;

            case 'material':
              materials({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialSort,
                },
              });
              break;

            case 'ageGroup':
              ageGroups({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength + 1,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'minAge',
                    order: SORT?.ASC,
                  },
                },
              });
              break;

            case 'facilitates':
              facilitates({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialSort,
                },
              });
              break;

            case 'tags':
              tags({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const getFilterData = (confirm) => {
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: '',
          brandIds: whereFilter?.brand,
          categoryIds: whereFilter?.category,
          materialIds: whereFilter?.material,
          ageGroupIds: whereFilter?.ageGroup,
          facilitateIds: whereFilter?.facilitates,
          tagIds: whereFilter?.tags,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35} size="medium">
          {showSearch && (
            <SearchComponent
              className="filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              query={filterSearch}
              setQuery={setFilterSearch}
              getData={(value) => handleFilterSearch(value, dataIndex)}
            />
          )}
          {filterTags?.[dataIndex]?.length > 0 && (
            <div className="filter-tags-wrapper">
              {map(filterTags?.[dataIndex], (item) => (
                <Tag
                  key={item?.key}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  <span>{item?.label}</span>
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onFilterScroll(e, dataIndex)}
          >
            {filterList?.length > 0
              ? map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              : isEmptyFilterList && (
                  <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'PRODUCT NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.toy?.name}</div>
          <div className="font-size-12">({record?.toy?.toyNumber})</div>
        </>
      ),
    },
    {
      title: 'IMAGES',
      dataIndex: 'images',
      key: 'images',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.toy?.images?.length > 0 ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.toy?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(record?.toy?.images);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Images</span>
          )}
          {record?.toy?.images?.length > 1
            ? ` + ${record?.toy?.images?.length - 1}`
            : ''}
        </span>
      ),
    },
    {
      title: 'BRAND',
      dataIndex: 'brand',
      key: 'brand',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.toy?.brand?.name,
      ...filterPopup('brand'),
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      key: 'category',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.categories?.map((category) => (
          <Tag key={category?.id}>{category?.name}</Tag>
        )),
      ...filterPopup('category'),
    },
    {
      title: 'MATERIAL',
      dataIndex: 'material',
      key: 'material',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.toy?.material?.name,
      ...filterPopup('material'),
    },
    {
      title: 'AGE GROUP',
      dataIndex: 'ageGroup',
      key: 'ageGroup',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.ageGroups?.map((ageGroup) =>
          ageGroup?.minAge > 18 ? (
            <Tag key={ageGroup?.id}>
              {Math.floor(ageGroup?.minAge / 12)} -{' '}
              {Math.floor(ageGroup?.maxAge / 12)} Years
            </Tag>
          ) : (
            <Tag key={ageGroup?.id}>
              {ageGroup?.minAge} - {ageGroup?.maxAge} Months
            </Tag>
          ),
        ),
      ...filterPopup('ageGroup', false),
    },
    {
      title: 'FACILITATES',
      dataIndex: 'facilitates',
      key: 'facilitates',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.facilitates?.length > 0
          ? record?.toy?.facilitates?.map((facilitate) => (
              <Tag key={facilitate?.id}>
                {facilitate?.name?.replace(/\s/g, '\u00a0')}
              </Tag>
            ))
          : '-',
      ...filterPopup('facilitates'),
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.tags?.length > 0
          ? record?.toy?.tags?.map((tag) => (
              <Tag key={tag?.id}>{tag?.name?.replace(/\s/g, '\u00a0')}</Tag>
            ))
          : '-',
      ...filterPopup('tags'),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyRackMasterList ? '' : <span />,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={ROUTES?.RACK_MASTER} />
        <div className="portal-header">View Shelf Toys</div>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setIsAddToyModalOpen(true)}
          >
            Add Toys
          </Button>
        </Portal>
      </Portal>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <Card className="full-height-without-nav">
        <CommonTable
          locale={locale}
          columns={columns}
          data={inventoryList || []}
          loading={isInventoryLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      </Card>
      <AddToysModal
        form={toyForm}
        onFinish={handleAddToys}
        libraryInventories={libraryInventories}
        isModalOpen={isAddToyModalOpen}
        setIsModalOpen={setIsAddToyModalOpen}
        libraryId={inventoryList?.[0]?.library?.id}
        toyFile={toyFile}
        setToyFile={setToyFile}
        toySelectValue={toySelectValue}
        setToySelectValue={setToySelectValue}
      />
    </>
  );
};

export default RackMasterInventory;
