import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ORDERS_ADMIN = gql`
  query OrdersAdmin($filter: OrderFilter, $sort: OrderSort) {
    ordersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        statusHistory {
          newStatus
          oldStatus
          createdAt
        }
        deliveryAttachments {
          url
          key
          deliveryAttachmentsType
        }
        orderNumber
        id
        deliveryType
        orderItemCount
        cancelledBy
        note
        deliveredOn
        deliveryPartner {
          id
          userNumber
          firstName
          lastName
        }
        user {
          id
          userNumber
          firstName
          lastName
          pincode {
            pincode
          }
        }
        library {
          name
          id
          libraryNumber
        }
        createdAt
        status
        cancellationData {
          date
          additionalComment
          reason
          type
        }
        comment {
          isResolved
          comment
        }
      }
    }
  }
`;

export const ORDER_ITEMS_ADMIN = gql`
  query OrderItemsAdmin($where: OrderUniqueInput) {
    orderItemsAdmin(where: $where) {
      count
      data {
        id
        returnDate
        adminNote
        contentHistory {
          id
          newContent {
            id
            quantity
            value
          }
          oldContent {
            id
            quantity
            value
          }
          status
          createdAt
        }
        deliveryConfirmationData {
          confirmedAt
          type
        }
        statusHistory {
          newStatus
          oldStatus
          createdAt
        }
        comment {
          isResolved
          comment
        }
        cancellationData {
          additionalComment
          date
          reason
          type
        }
        toy {
          id
          toyNumber
          name
          ageGroups {
            id
            minAge
            maxAge
          }
          brand {
            id
            name
          }
          bag {
            id
            name
            size
            image
          }
          images {
            contentType
            key
            order
            size
            url
          }
        }
        status
      }
    }
  }
`;

export const RETURN_ORDERS_ADMIN = gql`
  query returnOrdersAdmin(
    $filter: ReturnOrderItemFilter
    $sort: ReturnOrderSort
  ) {
    returnOrdersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        status
        toyCondition
        contentHistory {
          id
          newContent {
            id
            quantity
            value
          }
          oldContent {
            id
            quantity
            value
          }
          status
          createdAt
        }
        statusHistory {
          newStatus
          oldStatus
          createdAt
        }
        deliveryAttachments {
          url
          key
          deliveryAttachmentsType
        }
        productRate
        videoUrl
        images {
          key
          url
        }
        orderItem {
          toy {
            ageGroups {
              id
              minAge
              maxAge
            }
            brand {
              id
              name
            }
            bag {
              id
              name
              size
              image
            }
            images {
              contentType
              key
              order
              size
              url
            }
            name
            id
            toyNumber
          }
        }
        order {
          id
          orderNumber
          deliveryType
        }
        returnOrder {
          id
          returnOrderNumber
          library {
            id
            name
            libraryNumber
          }
          receivingLibrary {
            id
            name
            libraryNumber
          }
          user {
            firstName
            lastName
            userNumber
            id
            pincode {
              pincode
            }
          }
          deliveryPartner {
            firstName
            lastName
            userNumber
            id
          }
        }
        createdAt
        returnedOn
      }
    }
  }
`;

export const ORDERS_SELECT = gql`
  query OrdersAdmin($filter: OrderFilter, $sort: OrderSort) {
    ordersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        orderNumber
        id
      }
    }
  }
`;

export const RETURN_ORDERS_ADMIN_SELECT = gql`
  query returnOrdersAdmin(
    $filter: ReturnOrderItemFilter
    $sort: ReturnOrderSort
  ) {
    returnOrdersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        returnOrder {
          id
          returnOrderNumber
        }
      }
    }
  }
`;

export const TOYS = gql`
  query Toys($filter: ToysFilter, $sort: ToysSort) {
    toys(filter: $filter, sort: $sort) {
      data {
        id
        name
      }
      count
    }
  }
`;

export const GET_ORDER_CHALLAN_DOWNLOAD_URL_ADMIN = gql`
  query GetOrderChallanDownloadUrlAdmin($where: OrderUniqueInput!) {
    getOrderChallanDownloadUrlAdmin(where: $where) {
      challanUrl
    }
  }
`;

export const GET_ORDER_STICKER_DOWNLOAD_URL_ADMIN = gql`
  query GetOrderStickerDownloadUrlAdmin($where: OrderItemUniqueInput!) {
    getOrderStickerDownloadUrlAdmin(where: $where) {
      stickerUrl
    }
  }
`;
