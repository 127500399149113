import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import TransferIn from './pages/TransferIn';
import TransferOut from './pages/TransferOut';

const TransferLibraryWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}`}
      render={() => (
        <Redirect to={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.IN}`} />
      )}
    />
    <Route
      exact
      path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.IN}`}
      render={(props) => <TransferIn {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.OUT}`}
      render={(props) => <TransferOut {...props} />}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default TransferLibraryWrapper;
