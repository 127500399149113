import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { MENU_ITEMS } from '../../../../app/components/sidebar/Sidebar';
import { AppContext } from '../../../../AppContext';
import { MODULES, PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import { getRedirectSubmenuItem } from '../../../../common/utils';
import Error404 from '../../../../Error404';
import RedirectControl from '../../../../RedirectControl';
import TransferAll from './pages/TransferAll';
import TransferIn from './pages/TransferIn';
import TransferOut from './pages/TransferOut';

const tabs = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.ORDERS,
)?.subMenu?.find((menu) => menu?.key === MODULES?.TRANSFER)?.tabKeys;

const TranserOrdersWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectSubmenuItem(permissions, tabs);

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}`}
        render={() => (
          <RedirectControl
            redirectArray={redirectArray}
            route={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.IN}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.TRANSFER_ORDERS_IN}>
            <TransferIn {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.OUT}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.TRANSFER_ORDERS_OUT}>
            <TransferOut {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}${ROUTES?.ALL}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.TRANSFER_ORDERS_ALL}>
            <TransferAll {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default TranserOrdersWrapper;
