import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, find, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as PlayIcon } from '../../../../../assets/svg/play-solid.svg';
import { ReactComponent as FilterIcon } from '../../../../../assets/svg/sliders-solid.svg';
import { ReactComponent as DislikeRatingIcon } from '../../../../../assets/svg/thumbs-down-solid.svg';
import { ReactComponent as LikeRatingIcon } from '../../../../../assets/svg/thumbs-up-solid.svg';
import {
  DATE_FORMATS,
  DELIVERY_TYPE,
  DELIVERY_TYPE_OPTIONS,
  LIMIT,
  PRODUCT_RATE,
  REQUEST_TYPES,
  RETURN_ORDER_ITEM_STATUS,
  RETURN_ORDER_TABLE_ACTIONS_OPTIONS,
  SORT,
  USER_ROLES,
} from '../../../../../common/constants';
import {
  fileUpload,
  formatDate,
  hasNonEmptyValueObj,
  openNotification,
} from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import ContentMasterModal from '../../../../../modules/orders/modules/all/components/ContentMasterModal';
import ToyVideoModal from '../../../../raiseRequest/components/ToyVideoModal';
import NoteModal from '../../new/components/NoteModal';
import { PINCODES } from '../../new/graphql/Queries';
import {
  CONFIRM_RETURN_ORDER,
  CREATE_REQUEST_LIBRARY,
} from '../graphql/Mutations';
import {
  GET_LIBRARY_DAMAGE_REQUEST_IMAGE_SINGED_PUT_URL,
  GET_LIBRARY_REQUEST_TOY_VIDEO_SIGNED_PUT_URL,
  RETURN_ORDERS,
  RETURN_ORDERS_SELECT,
} from '../graphql/Queries';
import AllFilterReturnOrderModal from './AllFilterReturnOrderModal';
import AllReturnOrderItemModal from './AllReturnOrderItemModal';
import AllReturnOrderList from './AllReturnOrderList';
import ReportDamageModal from './ReportDamageModal';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialOrderFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialOrderSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const AllReturnTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: { isDesktop, currentUser },
    getCurrentUser,
  } = useContext(AppContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const returnId = queryParams?.get('returnId') || '';
  const status = queryParams?.get('status') || '';
  const [reportDamageForm] = Form.useForm();
  const { roles } = getCurrentUser() || {};
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [order, setOrder] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isNotePrompts, setIsNotePrompts] = useState(false);
  const [isReportDamageModalOpen, setIsReportDamageModalOpen] = useState(false);
  const [isReportDamageLoading, setIsReportDamageLoading] = useState(false);
  const [isConfirmReturnLoading, setIsConfirmReturnLoading] = useState(false);
  const [isConfirmReturnPrompts, setIsConfirmReturnPrompts] = useState(false);

  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [damageToyImage, setDamageToyImage] = useState([]);
  const [toyVideo, setToyVideo] = useState([]);
  const [toyPreviewImage, setToyPreviewImage] = useState();
  const [toyPreviewVideo, setToyPreviewVideo] = useState();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [orderItem, setOrderItem] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreOrderLoading, setIsFetchMoreOrderLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [
    isAllReturnOrderItemModalOpen,
    setIsAllReturnOrderItemModalOpen,
  ] = useState(false);
  const [isContentMasterModalOpen, setIsContentMasterModalOpen] = useState(
    false,
  );

  const [getLibraryRequestToyVideoSignedPutUrl] = useLazyQuery(
    GET_LIBRARY_REQUEST_TOY_VIDEO_SIGNED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {
        setIsReportDamageLoading(false);
      },
    },
  );

  const [returnOrders] = useLazyQuery(RETURN_ORDERS, {
    onCompleted: (response) => {
      if (isDesktop) {
        setOrderList([...response?.returnOrders?.data]);
      } else {
        setOrderList([...orderList, ...response?.returnOrders?.data]);
      }
      if (
        response?.returnOrders?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      if (response?.returnOrders?.data?.length < LIMIT) {
        setIsActive(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.returnOrders?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
      setIsFetchMoreOrderLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [pincodes, { loading: isPincodesLoading }] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      if (response?.pincodes?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.pincodes?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.pincodes?.data, (item) =>
        optionsCopy?.push({
          label: item?.pincode,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [
    returnOrdersSelect,
    { loading: isReturnOrdersSelectLoading },
  ] = useLazyQuery(RETURN_ORDERS_SELECT, {
    onCompleted: (response) => {
      if (response?.returnOrders?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.returnOrders?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.returnOrders?.data, (item) =>
        optionsCopy?.push({
          label: item?.returnOrder?.returnOrderNumber,
          key: item?.returnOrder?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [createRequestLibrary] = useMutation(CREATE_REQUEST_LIBRARY, {
    onError: () => {},
  });

  const [confirmReturnOrder] = useMutation(CONFIRM_RETURN_ORDER, {
    onError: () => {},
  });

  const [getSignUrl] = useLazyQuery(
    GET_LIBRARY_DAMAGE_REQUEST_IMAGE_SINGED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setOrderSearchTerm(returnId || orderSearchTerm);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setOrderList([]);
    let finalStatus;
    const { label, value } =
      find(map(RETURN_ORDER_ITEM_STATUS), {
        value: status,
      }) || {};
    if (value) {
      const filterValue = { status: [value] };
      const filterTagValue = { status: [{ label, key: value }] };
      finalStatus = [value];
      setFinalWhereFilters(filterValue);
      setWhereFilter(filterValue);
      setFilterTags(filterTagValue);
      setFinalFilterTags(filterTagValue);
    } else if (finalWhereFilters?.status?.length > 0) {
      finalStatus = finalWhereFilters.status;
    }
    returnOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: returnId || orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          deliveryType: finalWhereFilters?.deliveryType,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          returnedOn: {
            from: finalWhereFilters?.returnedOn?.from?.$d,
            to: finalWhereFilters?.returnedOn?.to?.$d,
          },
          status: finalStatus,
          returnOrderIds: finalWhereFilters?.returnOrderNumber,
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  }, [isDesktop, status]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    returnOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          skip,
          limit: pagination?.pageSize,
          search: orderSearchTerm,
          deliveryType: finalWhereFilters?.deliveryType,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          returnedOn: {
            from: finalWhereFilters?.returnedOn?.from?.$d,
            to: finalWhereFilters?.returnedOn?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          returnOrderIds: finalWhereFilters?.returnOrderNumber,
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setOrderList([]);
    setIsOrderLoading(true);
    returnOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          deliveryType: finalWhereFilters?.deliveryType,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          returnedOn: {
            from: finalWhereFilters?.returnedOn?.from?.$d,
            to: finalWhereFilters?.returnedOn?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          returnOrderIds: finalWhereFilters?.returnOrderNumber,
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleReportDamage = async (values) => {
    setIsReportDamageLoading(true);
    const uuid = uuidv4();
    let damageToyFileName = [];
    let video = '';
    if (values?.video?.length > 0) {
      const { name } = values?.video?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `toy/${uuid}/${newFilename}`;

      const res = await getLibraryRequestToyVideoSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getLibraryRequestToyVideoSignedPutUrl?.signedUrl,
              toyVideo?.[0]?.originFileObj,
            );
            video = res?.data?.getLibraryRequestToyVideoSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${toyVideo?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsReportDamageLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    if (!values?.damageToyImage?.url && values?.damageToyImage?.length > 0) {
      const toyImageArray = values?.damageToyImage?.map((item) => {
        const { name } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${uuid}/${newFilename}`;

        return fileKey;
      });

      const res = await getSignUrl({
        variables: {
          data: {
            fileNames: toyImageArray,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          },
        },
      });

      if (res?.data) {
        try {
          const response = await Promise.all(
            res?.data?.getLibraryDamageRequestImageSignedPutUrl?.map(
              async (signedData, index) => {
                try {
                  await fileUpload(
                    signedData?.signedUrl,
                    values?.damageToyImage?.[index]?.originFileObj,
                  );
                  return {
                    key: signedData?.fileName,
                  };
                } catch (error) {
                  throw new Error(
                    `${values?.damageToyImage?.[index]?.name} upload failed. Please try again.`,
                  );
                }
              },
            ),
          );

          damageToyFileName = response;
        } catch (error) {
          setIsReportDamageLoading(false);
          openNotification('error', error.message);
        }
      }
      setDamageToyImage([]);
    }
    try {
      const response = await createRequestLibrary({
        variables: {
          data: {
            images: damageToyFileName || undefined,
            videoKey: video,
            libraryDamageRequest: {
              returnOrderItemId: order?.id,
              includedContents: values?.includedContentMasters,
            },
            description: values?.description,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          },
        },
      });
      if (response?.data) {
        setIsOrderLoading(true);
        setIsReportDamageModalOpen(false);
        reportDamageForm?.resetFields();
        setOrderList([]);
        setToyVideo([]);
        setDamageToyImage([]);
        setToyPreviewVideo();
        setToyPreviewVideo();
        returnOrders({
          variables: {
            filter: {
              ...initialOrderFilter,
              skip:
                (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
              limit: paginationProp?.pageSize || LIMIT,
              search: orderSearchTerm,
              deliveryType: finalWhereFilters?.deliveryType,
              dateRange: {
                from: finalWhereFilters?.createdAt?.from?.$d,
                to: finalWhereFilters?.createdAt?.to?.$d,
              },
              status:
                finalWhereFilters?.status?.length > 0
                  ? finalWhereFilters?.status
                  : undefined,
              returnedOn: {
                from: finalWhereFilters?.returnedOn?.from?.$d,
                to: finalWhereFilters?.returnedOn?.to?.$d,
              },
              returnOrderIds: finalWhereFilters?.returnOrderNumber,
              pincodeIds: finalWhereFilters?.pincodes,
            },
            sort: sortedInfo?.column
              ? {
                  field: sortedInfo?.field,
                  order:
                    sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
                }
              : initialOrderSort,
          },
        });
      }
    } catch (error) {
      return error;
    }
    setIsReportDamageLoading(false);
  };

  const handleConfirmReturn = async () => {
    setIsConfirmReturnLoading(true);
    setOrderList([]);
    const response = await confirmReturnOrder({
      variables: {
        where: { id: order?.returnOrder?.id },
      },
    });
    if (response?.data) {
      setOrderList([]);
      setIsOrderLoading(true);
      setIsConfirmReturnPrompts(false);
      returnOrders({
        variables: {
          filter: {
            ...initialOrderFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            returnedOn: {
              from: finalWhereFilters?.returnedOn?.from?.$d,
              to: finalWhereFilters?.returnedOn?.to?.$d,
            },
            returnOrderIds: finalWhereFilters?.returnOrderNumber,
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
    setIsConfirmReturnLoading(false);
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setIsAllFilterModalOpen(false);
    setOrderList([]);
    setIsActive(true);
    const filters = {
      status: values?.status,
      createdAt: values?.createdAt?.[1]
        ? { from: values?.createdAt?.[0], to: values?.createdAt?.[1] }
        : null,
      returnedOn: values?.returnedOn?.[1]
        ? { from: values?.returnedOn?.[0], to: values?.returnedOn?.[1] }
        : null,
      pincodes: values?.pincodes,
      deliveryType: values?.deliveryType,
    };
    setWhereFilter(filters);
    setFinalWhereFilters(filters);
    returnOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: orderSearchTerm,
          deliveryType: values?.deliveryType,
          dateRange: {
            from: values?.createdAt?.[0],
            to: values?.createdAt?.[1],
          },
          returnedOn: {
            from: values?.returnedOn?.[0],
            to: values?.returnedOn?.[1],
          },
          status: values?.status?.length > 0 ? values?.status : undefined,
          pincodeIds: values?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleOrderScroll = () => {
    if (!isFetchMoreOrderLoading && !isOrderLoading) {
      const currentLength = orderList?.length;
      setIsFetchMoreOrderLoading(true);
      returnOrders({
        variables: {
          filter: {
            ...initialOrderFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            returnedOn: {
              from: finalWhereFilters?.returnedOn?.from?.$d,
              to: finalWhereFilters?.returnedOn?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            returnOrderIds: finalWhereFilters?.returnOrderNumber,
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleOrderScroll, isActive);

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    returnOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          deliveryType: whereFilter?.deliveryType,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          returnedOn: {
            from: whereFilter?.returnedOn?.from?.$d,
            to: whereFilter?.returnedOn?.to?.$d,
          },
          returnOrderIds: whereFilter?.returnOrderNumber,
          pincodeIds: whereFilter?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'deliveryType') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOrderLoading(true);
    returnOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          deliveryType: whereFiltersCopy?.deliveryType,
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          returnedOn: {
            from: whereFiltersCopy?.returnedOn?.from?.$d,
            to: whereFiltersCopy?.returnedOn?.to?.$d,
          },
          returnOrderIds: whereFiltersCopy?.returnOrderNumber,
          pincodeIds: whereFiltersCopy?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'returnOrderNumber':
          returnOrdersSelect({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'pincodes':
          pincodes({
            variables: {
              filter: {
                ...initialTableFilter,
              },
              sort: {
                field: 'pincode',
                order: 'ASC',
              },
            },
          });
          break;

        case 'deliveryType':
          forEach(DELIVERY_TYPE_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'status':
          forEach(RETURN_ORDER_ITEM_STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isPincodesLoading && !isReturnOrdersSelectLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'returnOrderNumber':
              returnOrdersSelect({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'pincodes':
              pincodes({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'pincode',
                    order: 'ASC',
                  },
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'returnOrderNumber':
        returnOrdersSelect({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'pincodes':
        pincodes({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'pincode',
              order: 'ASC',
            },
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'returnedOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'deliveryType') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={DELIVERY_TYPE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.PAID?.value ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.FREE?.value ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleChange = (value, record) => {
    setOrder(record);
    if (value === RETURN_ORDER_ITEM_STATUS?.RETURN_CONFIRMED?.value) {
      setIsConfirmReturnPrompts(true);
    } else {
      setIsReportDamageModalOpen(true);
    }
  };

  const columns = [
    {
      title: 'PRODUCT RETURN ID',
      dataIndex: 'returnOrderNumber',
      key: 'returnOrderNumber',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.returnOrder?.returnOrderNumber,
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      ellipsis: true,
      align: 'left',
      width: 250,
      className: 'max-width-column',
      render: (_, record) => {
        const toyData = record?.orderItem?.toy;
        return (
          <>
            <div className="table-data-with-id">{toyData?.name || '-'}</div>
            {toyData?.toyNumber && (
              <div className="font-size-12">({toyData?.toyNumber})</div>
            )}
          </>
        );
      },
    },
    {
      title: 'ORDER ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.order?.orderNumber,
    },
    {
      title: 'DELIVERY TYPE',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) =>
        DELIVERY_TYPE?.[record?.order?.deliveryType]?.label,
      ...filterPopup('deliveryType'),
    },
    {
      title: 'USER ID',
      dataIndex: 'userId',
      key: 'userId',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) => record?.returnOrder?.user?.userNumber || '-',
    },
    {
      title: 'OWNER LIBRARY NAME',
      dataIndex: 'ownerLibraryName',
      key: 'ownerLibraryName',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      render: (_, record) => {
        const ownerLib = record?.returnOrder?.library;
        return ownerLib ? (
          <>
            <div className="table-data-with-id">{ownerLib?.name}</div>
            <div className="font-size-12">({ownerLib?.libraryNumber})</div>
          </>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'RECEIVING LIBRARY NAME',
      dataIndex: 'receivingLibraryName',
      key: 'receivingLibraryName',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      render: (_, record) => {
        const receivingLib = record?.returnOrder?.receivingLibrary;
        return receivingLib ? (
          <>
            <div className="table-data-with-id">{receivingLib?.name}</div>
            <div className="font-size-12">({receivingLib?.libraryNumber})</div>
          </>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'DELIVERY PARTNER NAME',
      dataIndex: 'deliveryPartnerName',
      key: 'deliveryPartnerName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) => (
        <>
          {record?.returnOrder?.deliveryPartner?.firstName ||
          record?.returnOrder?.deliveryPartner?.lastName ? (
            <div className="table-data-with-id">{`${
              record?.returnOrder?.deliveryPartner?.firstName
                ? record?.returnOrder?.deliveryPartner?.firstName
                : ''
            } ${
              record?.returnOrder?.deliveryPartner?.lastName
                ? record?.returnOrder?.deliveryPartner?.lastName
                : ''
            }`}</div>
          ) : (
            '-'
          )}
          {record?.returnOrder?.deliveryPartner?.userNumber ? (
            <div className="font-size-12">
              ({record?.returnOrder?.deliveryPartner?.userNumber})
            </div>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'PINCODE',
      dataIndex: 'pincode',
      key: 'pincode',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) => record?.returnOrder?.pincode?.pincode || '-',
      ...filterPopup('pincodes'),
    },
    {
      title: 'PLACED ON',
      dataIndex: 'placedOn',
      key: 'placedOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME),
      ...filterPopup('createdAt'),
    },
    {
      title: 'RETURNED ON',
      dataIndex: 'deliveredOn',
      key: 'deliveredOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.returnedOn, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('returnedOn'),
    },
    {
      title: 'PICKED UP ATTACHMENT',
      dataIndex: 'pickedUpAttachments',
      key: 'pickedUpAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const pickedUpAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            [RETURN_ORDER_ITEM_STATUS?.PICKED_UP?.value, null]?.includes(
              attachment?.deliveryAttachmentsType,
            ),
        );

        const hasAttachments = pickedUpAttachments?.length > 0;
        const additionalCount = pickedUpAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={pickedUpAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(pickedUpAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'RETURNED ATTACHMENTS',
      dataIndex: 'returnedAttachments',
      key: 'returnedAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const returnedAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            attachment?.deliveryAttachmentsType ===
            RETURN_ORDER_ITEM_STATUS?.RETURNED?.value,
        );

        const hasAttachments = returnedAttachments?.length > 0;
        const additionalCount = returnedAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={returnedAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(returnedAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => RETURN_ORDER_ITEM_STATUS?.[record?.status]?.label,
      ...filterPopup('status', false),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: 350,
      render: (_, record) => (
        <div className="d-flex">
          <div className="mr-16">
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => {
                setOrder(record);
                setIsContentMasterModalOpen(true);
              }}
            >
              View Content Logs
            </Button>
          </div>
          <SelectComponent
            size="small"
            className="full-width"
            placeholder="Select Action"
            showAction={['click']}
            showSearch={false}
            value={null}
            options={RETURN_ORDER_TABLE_ACTIONS_OPTIONS?.filter((option) =>
              record?.status ===
              RETURN_ORDER_ITEM_STATUS?.RETURN_CONFIRMED?.value
                ? option?.value !==
                  RETURN_ORDER_ITEM_STATUS?.RETURN_CONFIRMED?.value
                : option?.value ===
                  RETURN_ORDER_ITEM_STATUS?.RETURN_CONFIRMED?.value,
            )}
            onChange={(value) => handleChange(value, record)}
            disabled={
              record?.isLibraryDamageRequestGenerated ||
              (record?.status !== RETURN_ORDER_ITEM_STATUS?.RETURNED?.value &&
                record?.status !==
                  RETURN_ORDER_ITEM_STATUS?.RETURN_CONFIRMED?.value) ||
              (record?.returnOrder?.receivingLibraryId &&
                currentUser?.libraryId === record?.returnOrder?.library?.id)
            }
            allowClear={false}
          />
        </div>
      ),
    },
  ]?.filter((item) => {
    if (
      !roles?.includes(USER_ROLES?.LIBRARIAN?.key) &&
      item?.dataIndex === 'actions'
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const expandedRowRender = (data) => {
    const nestedData = [
      {
        id: data?.id,
        productName: data?.orderItem?.toy?.name,
        ageGroups: data?.orderItem?.toy?.ageGroups,
        toyCondition: data?.toyCondition,
        images: data?.images,
        productRate: data?.productRate,
        videoUrl: data?.videoUrl,
        returnOrderNumber: data?.returnOrder?.returnOrderNumber,
        brand: data?.orderItem?.toy?.brand?.name,
        productImages: data?.orderItem?.toy?.images,
        packagingMaterial: data?.orderItem?.toy?.bag,
      },
    ];

    const nestedColumns = [
      {
        title: 'PRODUCT NAME',
        dataIndex: 'productName',
        key: 'productName',
        ellipsis: true,
        align: 'left',
        width: 250,
        className: 'max-width-column',
      },
      {
        title: 'IMAGES',
        dataIndex: 'productImages',
        key: 'productImages',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => {
          const images = record?.productImages;
          return (
            <span>
              {images?.length > 0 ? (
                <>
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={images?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(images);
                    }}
                    className="pointer"
                  />
                </>
              ) : (
                <span>No Images</span>
              )}
              {images?.length > 1 ? ` + ${images?.length - 1}` : ''}
            </span>
          );
        },
      },
      {
        title: 'BRAND',
        dataIndex: 'brand',
        key: 'brand',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => record?.brand || '-',
      },
      {
        title: 'AGE GROUP',
        dataIndex: 'ageGroup',
        key: 'ageGroup',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.ageGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          ),
      },
      {
        title: 'PACKAGING MATERIAL',
        dataIndex: 'packagingMaterial',
        key: 'packagingMaterial',
        ellipsis: true,
        width: 250,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <>
            {record?.packagingMaterial ? (
              <div>
                <span className="mr-8">
                  {record?.packagingMaterial?.name} (
                  {record?.packagingMaterial?.size})
                </span>
                <Image
                  preview={false}
                  width={22}
                  src={record?.packagingMaterial?.image}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setPreviewVisible(true);
                    setToyPreviewImage(record?.packagingMaterial?.image);
                  }}
                  className="pointer"
                />
              </div>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        title: 'RETURN TOY IMAGES',
        dataIndex: 'image',
        key: 'image',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <span>
            {record?.images?.length > 0 ? (
              <>
                <Image
                  preview={{
                    visible: false,
                  }}
                  width={20}
                  height={20}
                  src={record?.images?.[0]?.url}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(true);
                    setToyImages(record?.images);
                  }}
                  className="pointer"
                />
              </>
            ) : (
              <span>No Images</span>
            )}
            {record?.images?.length > 1
              ? ` + ${record?.images?.length - 1}`
              : ''}
          </span>
        ),
      },
      {
        title: 'VIDEO',
        dataIndex: 'video',
        key: 'video',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <span>
            {record?.videoUrl ? (
              <Tooltip title="Play" placement="top" zIndex={4}>
                <PlayIcon
                  onClick={() => {
                    setOrderItem(record);
                    setIsVideoModalOpen(true);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              '-'
            )}
          </span>
        ),
      },
      {
        title: 'PRODUCT RATING',
        dataIndex: 'productRate',
        key: 'productRate',
        ellipsis: true,
        align: 'left',
        width: 250,
        className: 'max-width-column',
        render: (_, record) => (
          <div className="action-button">
            {record?.productRate === PRODUCT_RATE?.LIKE ? (
              <Tooltip title="Rated" placement="top">
                <LikeRatingIcon className="mr-16 svg-icon" />
              </Tooltip>
            ) : (
              <Tooltip title="Unrated" placement="top">
                <DislikeRatingIcon className="mr-16 svg-icon" />
              </Tooltip>
            )}
          </div>
        ),
      },
    ];

    return (
      <CommonTable
        columns={nestedColumns}
        dataSource={nestedData || []}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  const handleCancel = () => {
    setIsContentMasterModalOpen(false);
    setOrder({});
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isConfirmReturnPrompts}
        onCancel={() => setIsConfirmReturnPrompts(false)}
        footer={[
          <div key="modal-actions">
            <Button
              onClick={handleConfirmReturn}
              type="primary"
              loading={isConfirmReturnLoading}
            >
              Confirm Return
            </Button>
            <Button
              onClick={() => {
                setIsConfirmReturnPrompts(false);
                setIsReportDamageModalOpen(true);
              }}
              type="primary"
            >
              Report Damage
            </Button>
            <Button
              onClick={() => setIsConfirmReturnPrompts(false)}
              className="cancel-btn"
            >
              Cancel
            </Button>
          </div>,
        ]}
      >
        Are you sure you want to
        <strong> Confirm Return / Report Damage </strong>
        this order item?
      </Modal>
      <ContentMasterModal
        isModalOpen={isContentMasterModalOpen}
        contentHistory={order?.contentHistory}
        handleCancel={handleCancel}
        isReturn
      />
      <ToyVideoModal
        title={`Video - ${orderItem?.returnOrderNumber}`}
        isModalOpen={isVideoModalOpen}
        setIsModalOpen={setIsVideoModalOpen}
        videoUrl={orderItem?.videoUrl}
      />
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <CommonPreview
        visible={previewVisible}
        setVisible={setPreviewVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <ReportDamageModal
        isModalOpen={isReportDamageModalOpen}
        setIsModalOpen={setIsReportDamageModalOpen}
        isBtnLoading={isReportDamageLoading}
        onFinish={handleReportDamage}
        damageToyImage={damageToyImage}
        setDamageToyImage={setDamageToyImage}
        form={reportDamageForm}
        toyVideo={toyVideo}
        setToyVideo={setToyVideo}
        toyPreviewImage={toyPreviewImage}
        setToyPreviewImage={setToyPreviewImage}
        toyPreviewVideo={toyPreviewVideo}
        setToyPreviewVideo={setToyPreviewVideo}
        order={order}
        setOrder={setOrder}
      />
      <NoteModal
        note={order?.cancellationData}
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
      />
      <div className="d-flex justify-end mb-16 align-center">
        <SearchComponent
          className={isDesktop ? 'search-box' : ''}
          query={orderSearchTerm}
          setQuery={setOrderSearchTerm}
          getData={handleSearch}
        />
        {!isDesktop && (
          <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
            <FilterIcon
              className="mobile-svg-icon ml-16"
              onClick={() => setIsAllFilterModalOpen(true)}
            />
          </Badge>
        )}
      </div>
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={orderList || []}
          loading={isOrderLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
          }}
        />
      ) : (
        <div className="mobile-container-tab no-scroll-bar">
          <AllFilterReturnOrderModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <AllReturnOrderItemModal
            order={order}
            setOrder={setOrder}
            isModalOpen={isAllReturnOrderItemModalOpen}
            setIsModalOpen={setIsAllReturnOrderItemModalOpen}
            setVisible={setVisible}
            setToyImages={setToyImages}
          />
          <AllReturnOrderList
            orderList={orderList}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreOrderLoading={isFetchMoreOrderLoading}
            isEmptyOrderList={isEmptyOrderList}
            isOrderLoading={isOrderLoading}
            handleChange={handleChange}
            setOrder={setOrder}
            setIsContentMasterModalOpen={setIsContentMasterModalOpen}
            setIsAllReturnOrderItemModalOpen={setIsAllReturnOrderItemModalOpen}
            setVisible={setVisible}
            setToyImages={setToyImages}
          />
        </div>
      )}
    </>
  );
};

export default AllReturnTable;
