import { Divider, Form } from 'antd';
import React, { useState } from 'react';
import ModalComponent from '../../../components/ModalComponent';
import ToySelect from './ToySelect';

const { Item } = Form;

const AddToysModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  libraryId,
  toyFile,
  setToyFile,
  toySelectValue,
  setToySelectValue,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setToyFile([]);
    setToySelectValue([]);
    form?.resetFields();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Add Toys to Shelf"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        okButtonProps={{
          disabled: toyFile?.length === 0 && toySelectValue?.length === 0,
        }}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item label="Toys" name="toys">
              <ToySelect
                libraryId={libraryId}
                disabled={toyFile?.length > 0}
                onChange={(values) => setToySelectValue(values)}
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default AddToysModal;
