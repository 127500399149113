import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TRANSFER_ORDER = gql`
  query TransferOrders($filter: TransferOrderFilter, $sort: TransferOrderSort) {
    transferOrders(filter: $filter, sort: $sort) {
      data {
        transferOrderNumber
        createdAt
        actionUser {
          userNumber
          firstName
          id
          lastName
        }
        creator {
          userNumber
          firstName
          id
          lastName
        }
        id
        metaData {
          damageNote
          requestedOn
          images {
            key
            url
          }
          videoUrl
        }
        orderItem {
          id
          status
          toy {
            name
            toyNumber
            id
          }
          order {
            id
            status
            orderNumber
          }
        }
        ownerLibrary {
          id
          libraryNumber
          name
        }
        receivingLibrary {
          id
          libraryNumber
          name
        }
        requestGenerator {
          userNumber
          firstName
          id
          lastName
        }
        status
        returnOrderItem {
          id
          status
          returnOrder {
            id
            status
            returnOrderNumber
          }
        }
        updatedAt
      }
      count
    }
  }
`;

export const GET_LIBRARY_DAMAGE_REQUEST_IMAGE_SINGED_PUT_URL = gql`
  query GetLibraryDamageRequestImageSignedPutUrl(
    $data: GetLibraryDamageRequestSignedPutUrlV2Input
  ) {
    getLibraryDamageRequestImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
