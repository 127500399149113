import { useLazyQuery } from '@apollo/client';
import { Button, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import LibraryForm from '../components/LibraryForm';
import LibraryTabs from '../components/LibraryTabs';
import { LIBRARY_ADMIN } from '../graphql/Queries';

const ViewLibrary = ({
  match: { params: { id, libraryNumber } = {} } = {},
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [libraryDetail, setLibraryDetail] = useState({});
  const [isLibraryLoading, setIsLibraryLoading] = useState(true);

  const [libraryAdmin] = useLazyQuery(LIBRARY_ADMIN, {
    onCompleted: (response) => {
      setLibraryDetail(response?.libraryAdmin);
      setIsLibraryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsLibraryLoading(true);
    setLibraryDetail([]);
    libraryAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const initialValues = {
    ...libraryDetail,
    id: libraryDetail?.libraryNumber,
    contact: libraryDetail?.librarian?.contactNo,
    email: libraryDetail?.librarian?.email,
    firstName: libraryDetail?.librarian?.firstName,
    lastName: libraryDetail?.librarian?.lastName,
    pincode: libraryDetail?.librarian?.pincode?.id,
    state: libraryDetail?.state,
    city: libraryDetail?.city,
    from: dayjs(libraryDetail?.preferredTimeFrom),
    to: dayjs(libraryDetail?.preferredTimeTo),
    libraryPincode: libraryDetail?.pincodes?.map((pincode) => pincode?.pincode),
    backupLibName: libraryDetail?.backUpLibrary?.name,
    backupLibId: libraryDetail?.backUpLibrary?.libraryNumber,
    backupLibLibrarianFirstName:
      libraryDetail?.backUpLibrary?.librarian?.firstName,
    backupLibLibrarianLastName:
      libraryDetail?.backUpLibrary?.librarian?.lastName,
    backupLibFrom: dayjs(libraryDetail?.backUpLibrary?.preferredTimeFrom),
    backupLibTo: dayjs(libraryDetail?.backUpLibrary?.preferredTimeTo),
    backupLibLibrarianContact:
      libraryDetail?.backUpLibrary?.librarian?.contactNo,
    backupLibLibrarianEmail: libraryDetail?.backUpLibrary?.librarian?.email,
    latitude: libraryDetail?.latLong?.coordinates?.[1],
    longitude: libraryDetail?.latLong?.coordinates?.[0],
    referralCode: libraryDetail?.referralCode,
    availableBalance: libraryDetail?.availableBalance,
    serviceableArea: libraryDetail?.serviceableArea,
    facilityType: libraryDetail?.types,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`}
        />
        <div className="portal-header">View Library</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/edit/${id}/${libraryNumber}`,
                )
              }
              disabled={libraryDetail?.isArchived}
            >
              Edit Library
            </Button>
          </div>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/edit/${id}/${libraryNumber}`,
                )
              }
              disabled={libraryDetail?.isArchived}
            >
              Edit Library
            </Button>
          </div>
        )}
      </Portal>
      <LibraryTabs
        libraryId={id}
        libraryNumber={libraryNumber}
        onboardedOn={libraryDetail?.createdAt}
        isView
      >
        {isLibraryLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isLibraryLoading}
          />
        ) : (
          <div className="library-form">
            <LibraryForm
              form={form}
              initialValues={initialValues}
              libraryDetail={libraryDetail}
              isView
            />
          </div>
        )}
      </LibraryTabs>
    </>
  );
};

export default ViewLibrary;
