import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_TRANSFER_ORDERS_ADMIN = gql`
  mutation UpdateTransferOrdersAdmin(
    $where: TransferOrdersInput!
    $data: UpdateTransferOrderInput!
  ) {
    updateTransferOrdersAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_TRANSFER_ORDER_EXPORT_ADMIN = gql`
  mutation RequestTransferOrderExportAdmin(
    $data: RequestExportInput!
    $filter: TransferOrderExportFilter!
  ) {
    requestTransferOrderExportAdmin(data: $data, filter: $filter) {
      message
    }
  }
`;
