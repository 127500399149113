import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_TRANSFER_ORDERS = gql`
  mutation UpdateTransferOrders(
    $where: TransferOrdersInput!
    $data: UpdateTransferOrderInput!
  ) {
    updateTransferOrders(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_TRANSFER_ORDER_EXPORT = gql`
  mutation RequestTransferOrderExport(
    $data: RequestExportInput!
    $filter: TransferOrderExportFilter!
  ) {
    requestTransferOrderExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const GET_LIBRARY_REQUEST_TOY_VIDEO_SIGNED_PUT_URL = gql`
  query GetLibraryRequestToyVideoSignedPutUrl(
    $data: GetLibraryDamageRequestSignedPutUrlInput!
  ) {
    getLibraryRequestToyVideoSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
