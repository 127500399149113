import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import { fileUpload, openNotification } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import ToyForm from '../components/ToyForm';
import { UPDATE_TOY } from '../graphql/Mutations';
import {
  GET_TOYS_SIGNED_URL,
  GET_TOY_VIDEO_SIGNED_PUT_URL,
  TOY_ADMIN,
} from '../graphql/Queries';

const EditToy = (props) => {
  const { match: { params: { id } = {} } = {} } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [toyDetail, setToyDetail] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isToyLoading, setIsToyLoading] = useState(true);
  const [toyImages, setToyImages] = useState([]);
  const [featuredImage, setFeaturedImage] = useState([]);
  const [isFeaturedImageChanged, setIsFeaturedImageChanged] = useState(false);
  const [toyVideo, setToyVideo] = useState([]);

  const [getToysSignUrl] = useLazyQuery(GET_TOYS_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsSubmitLoading(false);
    },
  });

  const [getToyVideoSignedPutUrl] = useLazyQuery(GET_TOY_VIDEO_SIGNED_PUT_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsSubmitLoading(false);
    },
  });

  const [updateToy] = useMutation(UPDATE_TOY, {
    onError: () => {},
  });

  const [toyAdmin] = useLazyQuery(TOY_ADMIN, {
    onCompleted: (response) => {
      const toyImagesArray = response?.toyAdmin?.images;
      const featuredImageObj = toyImagesArray?.shift();
      setToyImages(toyImagesArray);
      setFeaturedImage(featuredImageObj ? [featuredImageObj] : []);
      setToyVideo(
        response?.toyAdmin?.videoUrl
          ? { url: response?.toyAdmin?.videoUrl }
          : [],
      );
      setToyDetail({
        ...response?.toyAdmin,
        featuredImages: featuredImageObj ? [featuredImageObj] : [],
      });
      setIsToyLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsToyLoading(true);
    toyAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const updateToyFun = async (values) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const toyObj = {
      name: values?.name?.trim(),
      description: values?.description?.trim(),
      ageGroupIds: values?.ageGroup,
      categoryIds: values?.category,
      brandId: values?.brand,
      bagId: values?.packagingMaterial || null,
      materialId: values?.material,
      url: values?.url,
      shortDescription: values?.shortDescription?.trim(),
      eanNumber: values?.eanNumber?.trim(),
      price: parseFloat(values?.price),
      carbonEmission: parseFloat(values?.carbonEmission),
      toyNumber: values?.id?.trim(),
      facilitateIds: values?.facilitates,
      tagIds: values?.tags,
      genreIds: values?.genres,
      includedContentMasters: values?.includedContentMasters,
      excludedContentMasters: values?.excludedContentMasters,
      dimensions: values?.dimensions,
      type: values?.type,
      slug: values?.slug,
    };
    let images = [];
    let video = '';
    if (values?.video && !toyVideo?.url) {
      const { name } = values?.video?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `toy/${toyDetail?.imageUuid}/${newFilename}`;

      const res = await getToyVideoSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            imageUuid: toyDetail?.imageUuid,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getToyVideoSignedPutUrl?.signedUrl,
              toyVideo?.[0]?.originFileObj,
            );
            video = res?.data?.getToyVideoSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${toyVideo?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsSubmitLoading(false);
          openNotification('error', error.message);
        }
      }
    }

    if (values?.images) {
      const filteredValues = values?.images?.filter((image) => !image?.url);
      const filteredFeatureImageValue = values?.featuredImages?.filter(
        (image) => !image?.url,
      );
      const toyImgWithUrl = values?.images?.filter((image) => image?.url);
      const toyFeaturedImgWithUrl = values?.featuredImages?.filter(
        (image) => image?.url,
      );
      const toyAllImagWithUrl = toyFeaturedImgWithUrl?.concat(toyImgWithUrl);

      const initialImages = toyAllImagWithUrl
        ?.filter((image) => image?.url)
        ?.map((image, index) => ({
          contentType: image?.contentType,
          key: image?.key,
          order: index + 1,
          size: image?.size,
        }));

      const fileKeyArray = filteredValues?.map((item) => {
        const { name } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${toyDetail?.imageUuid}/${newFilename}`;

        return fileKey;
      });

      const featuredImageArray = filteredFeatureImageValue?.map((item) => {
        const { name } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${toyDetail?.imageUuid}/${newFilename}`;

        return fileKey;
      });

      const newArray = featuredImageArray?.concat(fileKeyArray);

      const res = await getToysSignUrl({
        variables: {
          data: {
            fileNames: newArray,
            imageUuid: toyDetail?.imageUuid,
          },
        },
      });

      const toyAllImages = filteredFeatureImageValue?.concat(filteredValues);

      if (res?.data) {
        const toySignedUrls = res?.data?.getToyImagesSignedPutUrl;
        if (isFeaturedImageChanged) {
          try {
            const toySignedUrl = toySignedUrls?.shift();
            const response = await Promise.all(
              [toySignedUrl]?.map(async (signedData, index) => {
                try {
                  await fileUpload(
                    signedData?.signedUrl,
                    toyAllImages?.[index]?.originFileObj,
                  );
                  return {
                    contentType: toyAllImages?.[index]?.type,
                    key: signedData?.fileName,
                    order: 1,
                    size: `${toyAllImages?.[index]?.size}`,
                  };
                } catch (error) {
                  throw new Error(
                    `${toyAllImages?.[index]?.name} upload failed. Please try again.`,
                  );
                }
              }),
            );

            images = [...response];
          } catch (error) {
            setIsSubmitLoading(false);
            openNotification('error', error.message);
          }
        }
        try {
          const response = await Promise.all(
            toySignedUrls?.map(async (signedData, index) => {
              try {
                await fileUpload(
                  signedData?.signedUrl,
                  toyAllImages?.[isFeaturedImageChanged ? index + 1 : index]
                    ?.originFileObj,
                );
                return {
                  contentType:
                    toyAllImages?.[isFeaturedImageChanged ? index + 1 : index]
                      ?.type,
                  key: signedData?.fileName,
                  order: isFeaturedImageChanged
                    ? initialImages?.length + 2
                    : initialImages?.length + 1,
                  size: `${
                    toyAllImages?.[isFeaturedImageChanged ? index + 1 : index]
                      ?.size
                  }`,
                };
              } catch (error) {
                throw new Error(
                  `${
                    toyAllImages?.[isFeaturedImageChanged ? index + 1 : index]
                      ?.name
                  } upload failed. Please try again.`,
                );
              }
            }),
          );

          images = [...images, ...initialImages, ...response];
        } catch (error) {
          setIsSubmitLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    try {
      const response = await updateToy({
        variables: {
          where: {
            id,
          },
          data: {
            ...toyObj,
            images,
            videoKey: toyVideo?.url ? toyVideo?.[0]?.url : video,
          },
        },
      });
      if (response?.data) {
        setIsFeaturedImageChanged(false);
        history?.replace(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`);
      }
    } catch (error) {
      setIsSubmitLoading(false);
      return error;
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const initialValues = {
    ...toyDetail,
    category: toyDetail?.categories?.map((category) => category?.id),
    ageGroup: toyDetail?.ageGroups?.map((ageGroup) => ageGroup?.id),
    brand: toyDetail?.brand?.id,
    material: toyDetail?.material?.id,
    packagingMaterial: toyDetail?.bag?.id,
    eanNumber: toyDetail?.eanNumber,
    price: toyDetail?.price,
    id: toyDetail?.toyNumber,
    facilitates: toyDetail?.facilitates?.map((facilitate) => facilitate?.id),
    tags: toyDetail?.tags?.map((tag) => tag?.id),
    genres: toyDetail?.genres?.map((genre) => genre?.id),
    includedContentMasters: toyDetail?.includedContents?.map(
      (contentMaster) => ({
        id: contentMaster?.id,
        quantity: contentMaster?.quantity,
      }),
    ),
    excludedContentMasters: toyDetail?.excludedContents?.map(
      (contentMaster) => ({
        id: contentMaster?.id,
        quantity: contentMaster?.quantity,
      }),
    ),
    dimensions: toyDetail?.dimensions,
    type: toyDetail?.type,
    slug: toyDetail?.slug,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`} />
        <div className="portal-header">Edit Toy</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`)
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`)
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Toy Details">
        {isToyLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isToyLoading}
          />
        ) : (
          <div className="toy-form">
            <ToyForm
              form={form}
              onFinish={updateToyFun}
              handleShowPrompt={handleShowPrompt}
              initialFormValues={initialValues}
              toyImages={toyImages}
              setToyImages={setToyImages}
              toyDetail={toyDetail}
              toyVideo={toyVideo}
              setToyVideo={setToyVideo}
              featuredImage={featuredImage}
              setFeaturedImage={setFeaturedImage}
              setIsFeaturedImageChanged={setIsFeaturedImageChanged}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default EditToy;
