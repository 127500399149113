import { Col, Form, Input, Row, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { NUMBER_FIELD_TYPES, PLAN_NAMES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import NumberComponent from '../../../components/NumberComponent';

const { Item } = Form;
const { positiveNumber } = formValidatorRules;

const SubscriptionForm = ({
  planDetails,
  form,
  isView,
  onFinish,
  setIsDisabled,
  planName,
  isNewPlan = false,
}) => {
  const { dispatch } = useContext(AppContext);
  const [validationTriggered, setValidationTriggered] = useState(false);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const subscriptionFeatures = planDetails?.subscriptionFeatures;
  const config = planDetails?.config;

  const initialValues = {
    ...planDetails,
    name: PLAN_NAMES?.[planDetails?.name]?.key,
    discount: subscriptionFeatures?.discount || '-',
    membershipFees: subscriptionFeatures?.memberShipFees,
    freeDeliveries: subscriptionFeatures?.totalFreeDeliveryPerMonth,
    bonusDeliveries: subscriptionFeatures?.bonusDeliveries,
    deliveryCharges: subscriptionFeatures?.deliveryCharges,
    holdingToys: subscriptionFeatures?.totalKeepCountPerMonth,
    deposits: subscriptionFeatures?.refundableDeposit,
    toyProgram: subscriptionFeatures?.toyProgram,
    billableAmount:
      parseInt(planDetails?.price || 0, 10) +
      (subscriptionFeatures?.memberShipFees !== '-'
        ? parseInt(subscriptionFeatures?.memberShipFees || 0, 10)
        : 0) +
      parseInt(subscriptionFeatures?.refundableDeposit || 0, 10),
    subscriptionFees: planDetails?.price,
    actualChargesForDamageWaiverFees:
      subscriptionFeatures?.actualChargesForDamageWaiverFees,
    actualChargesForSecurityDeposit:
      subscriptionFeatures?.actualChargesForSecurityDeposit,
    damageWaiverFees: subscriptionFeatures?.damageWaiverFees,
    damageWaiverFeesApplied: subscriptionFeatures?.damageWaiverFeesApplied,
    membershipFeesApplied: subscriptionFeatures?.membershipFeesApplied,
    securityDepositApplied: subscriptionFeatures?.securityDepositApplied,
    trialAmount: subscriptionFeatures?.trialAmount,
    trialApplied: subscriptionFeatures?.trialApplied,
    trialDays: subscriptionFeatures?.trialDays,
    actualChargesForMemberShipFees:
      subscriptionFeatures?.actualChargesForMemberShipFees,
    isUnlimitedFreeDelivery: subscriptionFeatures?.isUnlimitedFreeDelivery,
    phonePeOneTimePayment: config?.phonePeOneTimePayment,
    phonePeRecurringPayment: config?.phonePeRecurringPayment,
    razorPayOneTimePayment: config?.razorPayOneTimePayment,
    razorPayRecurringCardPayment: config?.razorPayRecurringCardPayment,
    razorPayRecurringUpiPayment: config?.razorPayRecurringUpiPayment,
    stripeRecurringCardPayment: config?.stripeRecurringCardPayment,
  };

  useEffect(() => {
    form?.setFieldsValue(initialValues);
  }, []);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFieldsChange={handleShowPrompt}
        disabled={isView}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        className={
          isView
            ? 'common-view-form subscription-form'
            : 'form subscription-form'
        }
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Item label="Provider ID" name="planId">
              <Input allowClear placeholder="Enter Provider ID" disabled />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Item label="Plan Name" name="name">
              <Input allowClear placeholder="Enter Plan Name" disabled />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Item label="Billable Amount" name="billableAmount">
              <Input allowClear placeholder="Enter Billable Amount" disabled />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Item label="Additional Discount" name="discount">
              <Input
                allowClear
                placeholder="Enter Additional Discount"
                disabled
              />
            </Item>
          </Col>
        </Row>
        {planName !== PLAN_NAMES?.TRIAL?.value && (
          <Row gutter={[16, 0]} align="middle">
            <Col xs={24}>
              <h2>Trial Period Details</h2>
            </Col>
            <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
              <div className="subscription-text">
                Trial Period Enable/Disable
              </div>
            </Col>
            <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
              <Item valuePropName="checked" name="trialApplied">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
              <div className="subscription-text">Collect Membership Fees</div>
            </Col>
            <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
              <Item valuePropName="checked" name="membershipFeesApplied">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
              <div className="subscription-text">Collect Security Deposits</div>
            </Col>
            <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
              <Item valuePropName="checked" name="securityDepositApplied">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
              <div className="subscription-text">Number of Trial Days</div>
            </Col>
            <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
              <Item
                name="trialDays"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Number of Trial Days!',
                  },
                ]}
              >
                <NumberComponent
                  allowClear
                  isFractional={false}
                  placeholder="Enter Number of Trial Days"
                  type={NUMBER_FIELD_TYPES?.TRIAL_PLAN_DAYS}
                />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
              <div className="subscription-text">
                Amount Charged for the Trial Period
              </div>
            </Col>
            <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
              <Item
                name="trialAmount"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Amount!',
                  },
                ]}
              >
                <NumberComponent
                  allowClear
                  isFractional={false}
                  placeholder="Enter Amount"
                  type={NUMBER_FIELD_TYPES?.PRICE}
                />
              </Item>
            </Col>
          </Row>
        )}
        {isNewPlan && (
          <Row gutter={[16, 0]} align="middle">
            <Col xs={24}>
              <h2>Payment Methods</h2>
            </Col>
            <Col xs={14} sm={16} md={18} xl={20}>
              <div className="subscription-text">PhonePe One-Time Payment</div>
            </Col>
            <Col xs={10} sm={8} md={6} xl={4} className="input">
              <Item valuePropName="checked" name="phonePeOneTimePayment">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} xl={20}>
              <div className="subscription-text">PhonePe Recurring Payment</div>
            </Col>
            <Col xs={10} sm={8} md={6} xl={4} className="input">
              <Item valuePropName="checked" name="phonePeRecurringPayment">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} xl={20}>
              <div className="subscription-text">RazorPay One-Time Payment</div>
            </Col>
            <Col xs={10} sm={8} md={6} xl={4} className="input">
              <Item valuePropName="checked" name="razorPayOneTimePayment">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} xl={20}>
              <div className="subscription-text">
                RazorPay Recurring Card Payment
              </div>
            </Col>
            <Col xs={10} sm={8} md={6} xl={4} className="input">
              <Item valuePropName="checked" name="razorPayRecurringCardPayment">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} xl={20}>
              <div className="subscription-text">
                RazorPay Recurring UPI Payment
              </div>
            </Col>
            <Col xs={10} sm={8} md={6} xl={4} className="input">
              <Item valuePropName="checked" name="razorPayRecurringUpiPayment">
                <Switch />
              </Item>
            </Col>
            <Col xs={14} sm={16} md={18} xl={20}>
              <div className="subscription-text">
                Stripe Recurring Card Payment
              </div>
            </Col>
            <Col xs={10} sm={8} md={6} xl={4} className="input">
              <Item valuePropName="checked" name="stripeRecurringCardPayment">
                <Switch />
              </Item>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 0]} align="middle">
          <Col xs={24}>
            <h2>Plan Details</h2>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">Subscription Fees</div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item name="subscriptionFees">
              <Input
                allowClear
                placeholder="Enter Subscription Fees"
                disabled
              />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">
              Number of Toys one can hold at a given time
            </div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item
              name="holdingToys"
              rules={[
                {
                  required: true,
                  message:
                    'Please Enter Number of Toys one can hold at a given time!',
                },
                positiveNumber,
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Number of Toys one can hold at a given time"
                type={NUMBER_FIELD_TYPES?.DELIVERY}
              />
            </Item>
          </Col>
          {isNewPlan && (
            <>
              <Col xs={14} sm={16} md={18} xl={20}>
                <div className="subscription-text">
                  Unlimited Free Delivery Enable/Disable
                </div>
              </Col>
              <Col xs={10} sm={8} md={6} xl={4} className="input">
                <Item valuePropName="checked" name="isUnlimitedFreeDelivery">
                  <Switch />
                </Item>
              </Col>
            </>
          )}
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">
              Number of Free Deliveries per Month
            </div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item
              name="freeDeliveries"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Number of Free Deliveries per Month!',
                },
                positiveNumber,
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Number of Free Deliveries per Month"
                type={NUMBER_FIELD_TYPES?.DELIVERY}
              />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">Bonus Deliveries</div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item
              name="bonusDeliveries"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Bonus Deliveries!',
                },
                positiveNumber,
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Bonus Deliveries"
                type={NUMBER_FIELD_TYPES?.DELIVERY}
              />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">
              Give a toy! Spread joy with the 'Toy Program'
            </div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item name="toyProgram">
              <Input allowClear placeholder="Enter Toy Program" disabled />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">Delivery Charges</div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item name="deliveryCharges">
              <Input allowClear placeholder="Enter Delivery Charges" disabled />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">Membership Fees</div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item name="membershipFees">
              <Input allowClear placeholder="Enter Membership Fees" disabled />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">
              Actual Charge For Membership Fees
            </div>
          </Col>
          <Col xs={10} sm={8} md={6} xl={4} className="input">
            <Item
              name="actualChargesForMemberShipFees"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Actual Charge For Membership Fees!',
                },
                positiveNumber,
              ]}
            >
              <NumberComponent
                allowClear
                placeholder="Enter Actual Charge For Membership Fees"
                type={NUMBER_FIELD_TYPES?.PRICE}
              />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">
              Refundable Security Deposits
            </div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item name="deposits">
              <Input
                allowClear
                placeholder="Enter Refundable Security Deposits"
                disabled
              />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">
              Actual Charge For Security Deposits
            </div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item
              name="actualChargesForSecurityDeposit"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Actual Charge For Security Deposits!',
                },
                positiveNumber,
              ]}
            >
              <NumberComponent
                allowClear
                placeholder="Enter Actual Charge For Security Deposits"
                type={NUMBER_FIELD_TYPES?.PRICE}
              />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">Collect Damage Waiver Fee</div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item valuePropName="checked" name="damageWaiverFeesApplied">
              <Switch />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">Damage Waiver Fees</div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item
              name="damageWaiverFees"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Damage Waiver Fees!',
                },
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Damage Waiver Fees"
                type={NUMBER_FIELD_TYPES?.PRICE}
              />
            </Item>
          </Col>
          <Col xs={14} sm={16} md={18} lg={18} xl={20} xxl={20}>
            <div className="subscription-text">
              Actual Charge For Damage Waiver Fees
            </div>
          </Col>
          <Col xs={10} sm={8} md={6} lg={6} xl={4} xxl={4} className="input">
            <Item
              name="actualChargesForDamageWaiverFees"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Actual Charge For Damage Waiver Fees!',
                },
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Actual Charge For Damage Waiver Fees"
                type={NUMBER_FIELD_TYPES?.PRICE}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SubscriptionForm;
